<template>
    <div>
        <v-row class="pa-4 pb-0" id="loginTradesDesc">
            <v-col
                :cols="cols"
                v-for="item in tileDisplay"
                :key="item.value"
                class="pt-0 pb-0"
                :class="{ 'pb-2': VUETIFY_BREAKPOINT.mobile }"
            >
                <v-card class="mx-auto" max-width="344">
                    <v-card-text>
                        <div>
                            {{ item.text }}
                        </div>
                        <p class="text text--primary mb-0">
                            {{
                                item.value === "server"
                                    ? server
                                    : item.value === "Symbol"
                                    ? symbol
                                    : item.value === "ProfitMode"
                                    ? profitModeOutput
                                    : mergedData[0] === undefined ||
                                      mergedData[0][item.value] === undefined
                                    ? "N/A"
                                    : mergedData[0][item.value]
                            }}
                        </p>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col :cols="cols" class="pt-0 pb-0">
                <v-card class="mx-auto" max-width="344">
                    <v-card-text>
                        <div>Refresh</div>
                        <v-btn
                            color="primary"
                            icon
                            @click="refresh"
                            x-small
                            id="loginTradesRefresh"
                        >
                            <v-icon>mdi-refresh</v-icon>
                        </v-btn>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <v-row class="pa-4 pt-0">
            <v-col cols="12">
                <v-expansion-panels
                    multiple
                    v-model="expansionList"
                    id="loginTradesExpansion"
                >
                    <v-expansion-panel>
                        <v-expansion-panel-header
                            class="pb-0"
                            style="font-weight: 800"
                        >
                            Open Trades
                        </v-expansion-panel-header>
                        <v-expansion-panel-content
                            :class="mobileExpansionContentClass"
                        >
                            <v-expansion-panel>
                                <v-expansion-panel-header class="pa-0">
                                    <div
                                        style="color: #2196f3"
                                        id="loginTradesCalculationReference"
                                    >
                                        Calculation Reference
                                    </div>
                                </v-expansion-panel-header>
                                <v-expansion-panel-content
                                    :class="mobileExpansionContentClass"
                                >
                                    <v-alert
                                        outlined
                                        type="info"
                                        border="left"
                                        dense
                                        prominent
                                    >
                                        <div>
                                            <b>Profit EOD (In USD): </b>

                                            <ul>
                                                <li>
                                                    <b>For buy trades: </b>
                                                    <ul>
                                                        <li>
                                                            <b
                                                                >If trade is
                                                                opened today:</b
                                                            >
                                                            (current bid price -
                                                            trade open price) *
                                                            lot * contract size
                                                            * exchange price
                                                        </li>
                                                        <li>
                                                            <b
                                                                >If trade is
                                                                opened before
                                                                today:</b
                                                            >
                                                            (current bid price -
                                                            bid price at
                                                            previous EOD) * lot
                                                            * contract size *
                                                            exchange price
                                                        </li>
                                                    </ul>
                                                </li>
                                                <li>
                                                    <b>For sell trades: </b>
                                                    <ul>
                                                        <li>
                                                            <b
                                                                >If trade is
                                                                opened today:</b
                                                            >
                                                            (trade open price -
                                                            current ask price) *
                                                            lot * contract size
                                                            * exchange price
                                                        </li>
                                                        <li>
                                                            <b
                                                                >If trade is
                                                                opened before
                                                                today:</b
                                                            >
                                                            (ask price at
                                                            previous EOD -
                                                            current ask price) *
                                                            lot * contract size
                                                            * exchange price
                                                        </li>
                                                    </ul>
                                                </li>
                                            </ul>
                                        </div>
                                        <div>
                                            <b>Profit MT (In USD): </b>
                                            <ul>
                                                <li>
                                                    <b>For buy trades: </b>
                                                    (current bid price - trade
                                                    open price) * lot * contract
                                                    size * exchange price
                                                </li>
                                                <li>
                                                    <b>For sell trades: </b>
                                                    (trade open price - current
                                                    ask price) * lot * contract
                                                    size * exchange price
                                                </li>
                                            </ul>
                                        </div>
                                    </v-alert>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                            <v-card>
                                <v-data-table
                                    :items-per-page="15"
                                    :headers="openTradesHeader"
                                    :header-props="{ sortIcon: null }"
                                    :items="loginOpenTradesData"
                                    :loading="openTradeLoading"
                                    :mobile-breakpoint="0"
                                    :footer-props="{
                                        'items-per-page-text': '',
                                    }"
                                    id="loginTradesDetail"
                                >
                                    <template v-slot:header.profit="{ header }">
                                        {{ header.text }}
                                        <v-tooltip top>
                                            <template
                                                v-slot:activator="{ on, attrs }"
                                            >
                                                <v-icon
                                                    dark
                                                    v-bind="attrs"
                                                    v-on="on"
                                                    small
                                                    color="grey"
                                                >
                                                    mdi-information
                                                </v-icon>
                                            </template>
                                            <span
                                                >MT Profit in Account
                                                Currency</span
                                            >
                                        </v-tooltip>
                                    </template>
                                    <template
                                        v-slot:header.ProfitTotal="{ header }"
                                    >
                                        {{ header.text }}
                                        <v-tooltip top>
                                            <template
                                                v-slot:activator="{ on, attrs }"
                                            >
                                                <v-icon
                                                    dark
                                                    v-bind="attrs"
                                                    v-on="on"
                                                    small
                                                    color="grey"
                                                >
                                                    mdi-information
                                                </v-icon>
                                            </template>
                                            <span>MT Profit In USD</span>
                                        </v-tooltip>
                                    </template>
                                    <template v-slot:item="{ item }">
                                        <tr>
                                            <td column="PositionID">
                                                {{ item["positionID"] }}
                                            </td>
                                            <td column="Order">
                                                {{ item["order"] }}
                                            </td>
                                            <td column="Cmd">
                                                {{
                                                    item["cmd"].toString() ===
                                                    "0"
                                                        ? "buy"
                                                        : item[
                                                              "cmd"
                                                          ].toString() === "1"
                                                        ? "sell"
                                                        : item["cmd"].toString()
                                                }}
                                            </td>
                                            <td column="Volume" align="right">
                                                {{
                                                    item["platform"] === "MT4"
                                                        ? (
                                                              item["volume"] /
                                                              100
                                                          ).toFixed(lotDigits)
                                                        : (
                                                              item[
                                                                  "currentVolume"
                                                              ] / 100
                                                          ).toFixed(lotDigits)
                                                }}
                                            </td>
                                            <td column="OpenTime">
                                                {{
                                                    toMTTimeString(
                                                        item["openTime"]
                                                    )
                                                }}
                                            </td>
                                            <td
                                                column="OpenPrice"
                                                align="right"
                                            >
                                                {{
                                                    decimalPlaceCorrection(
                                                        symbol,
                                                        item["openPrice"]
                                                    )
                                                }}
                                            </td>
                                            <td
                                                column="CurrentAsk"
                                                align="right"
                                            >
                                                {{
                                                    decimalPlaceCorrection(
                                                        symbol,
                                                        item["tradePriceInfo"][
                                                            "currentAsk"
                                                        ]
                                                    )
                                                }}
                                            </td>
                                            <td
                                                column="CurrentBid"
                                                align="right"
                                            >
                                                {{
                                                    decimalPlaceCorrection(
                                                        symbol,
                                                        item["tradePriceInfo"][
                                                            "currentBid"
                                                        ]
                                                    )
                                                }}
                                            </td>
                                            <td column="EodAsk" align="right">
                                                {{
                                                    decimalPlaceCorrection(
                                                        symbol,
                                                        item["tradePriceInfo"][
                                                            "eodAsk"
                                                        ]
                                                    )
                                                }}
                                            </td>
                                            <td column="EodBid" align="right">
                                                {{
                                                    decimalPlaceCorrection(
                                                        symbol,
                                                        item["tradePriceInfo"][
                                                            "eodBid"
                                                        ]
                                                    )
                                                }}
                                            </td>
                                            <td column="Profit" align="right">
                                                {{ item["profit"].toFixed(2) }}
                                            </td>
                                            <td
                                                column="ProfitTotal"
                                                align="right"
                                            >
                                                {{
                                                    item["tradeProfitInfo"][
                                                        "profitTotal"
                                                    ].toFixed(2)
                                                }}
                                            </td>
                                            <td
                                                column="ProfitEOD"
                                                align="right"
                                            >
                                                {{
                                                    item["tradeProfitInfo"][
                                                        "profitEOD"
                                                    ].toFixed(2)
                                                }}
                                            </td>
                                            <td column="Tp" align="right">
                                                {{
                                                    decimalPlaceCorrection(
                                                        symbol,
                                                        item["tp"]
                                                    )
                                                }}
                                            </td>
                                            <td column="Sl" align="right">
                                                {{
                                                    decimalPlaceCorrection(
                                                        symbol,
                                                        item["sl"]
                                                    )
                                                }}
                                            </td>
                                            <td column="Reason">
                                                {{
                                                    reasonList[
                                                        item[
                                                            "reason"
                                                        ].toString()
                                                    ]
                                                }}
                                            </td>
                                            <td
                                                column="Commission"
                                                align="right"
                                            >
                                                {{
                                                    item["commission"].toFixed(
                                                        2
                                                    )
                                                }}
                                            </td>
                                            <td column="Storage" align="right">
                                                {{ item["storage"].toFixed(2) }}
                                            </td>
                                            <td column="extraInfo">
                                                <v-tooltip top>
                                                    <template
                                                        v-slot:activator="{
                                                            on,
                                                            attrs,
                                                        }"
                                                    >
                                                        <v-icon
                                                            dark
                                                            v-bind="attrs"
                                                            v-on="on"
                                                            class="pr-2"
                                                        >
                                                            mdi-swap-horizontal
                                                        </v-icon>
                                                    </template>
                                                    <div>
                                                        <table>
                                                            <tr>
                                                                <td
                                                                    class="pr-2"
                                                                >
                                                                    Exchange
                                                                    Symbol:
                                                                </td>
                                                                <td>
                                                                    {{
                                                                        item[
                                                                            "tradePriceInfo"
                                                                        ][
                                                                            "exchangeSymbol"
                                                                        ]
                                                                    }}
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>
                                                                    Exchange
                                                                    Ask:
                                                                </td>
                                                                <td>
                                                                    {{
                                                                        item[
                                                                            "tradePriceInfo"
                                                                        ][
                                                                            "exchangeAsk"
                                                                        ].toFixed(
                                                                            2
                                                                        )
                                                                    }}
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>
                                                                    Exchange
                                                                    Bid:
                                                                </td>
                                                                <td>
                                                                    {{
                                                                        item[
                                                                            "tradePriceInfo"
                                                                        ][
                                                                            "exchangeBid"
                                                                        ].toFixed(
                                                                            2
                                                                        )
                                                                    }}
                                                                </td>
                                                            </tr>
                                                        </table>
                                                    </div>
                                                </v-tooltip>

                                                <v-tooltip top>
                                                    <template
                                                        v-slot:activator="{
                                                            on,
                                                            attrs,
                                                        }"
                                                    >
                                                        <v-icon
                                                            dark
                                                            v-bind="attrs"
                                                            v-on="on"
                                                            id="test"
                                                        >
                                                            mdi-calculator-variant
                                                        </v-icon>
                                                    </template>
                                                    <div>
                                                        <b
                                                            >Profit EOD (In
                                                            USD):</b
                                                        >
                                                        <div>
                                                            {{
                                                                calculationTooltipInWords(
                                                                    "open",
                                                                    "EOD",
                                                                    item
                                                                )
                                                            }}
                                                        </div>
                                                        <div>
                                                            {{
                                                                calculationTooltipInNumber(
                                                                    "open",
                                                                    "EOD",
                                                                    item
                                                                )
                                                            }}
                                                        </div>
                                                        <b
                                                            >Profit MT (In
                                                            USD):</b
                                                        >
                                                        <div>
                                                            {{
                                                                calculationTooltipInWords(
                                                                    "open",
                                                                    "MT",
                                                                    item
                                                                )
                                                            }}
                                                        </div>
                                                        <div>
                                                            {{
                                                                calculationTooltipInNumber(
                                                                    "open",
                                                                    "MT",
                                                                    item
                                                                )
                                                            }}
                                                        </div>
                                                    </div>
                                                </v-tooltip>
                                            </td>
                                        </tr>
                                    </template>
                                </v-data-table>
                            </v-card>
                        </v-expansion-panel-content> </v-expansion-panel
                    ><v-expansion-panel>
                        <v-expansion-panel-header
                            class="pb-0"
                            style="font-weight: 800"
                        >
                            Close Trades
                        </v-expansion-panel-header>
                        <v-expansion-panel-content
                            :class="mobileExpansionContentClass"
                        >
                            <v-expansion-panel>
                                <v-expansion-panel-header class="pa-0">
                                    <div style="color: #2196f3">
                                        Calculation Reference
                                    </div>
                                </v-expansion-panel-header>
                                <v-expansion-panel-content
                                    :class="mobileExpansionContentClass"
                                >
                                    <v-alert
                                        outlined
                                        type="info"
                                        border="left"
                                        dense
                                        class="ml-2 mr-2"
                                        prominent
                                    >
                                        <div>
                                            <b>Profit EOD (In USD):</b>

                                            <ul>
                                                <li>
                                                    <b>For buy trades: </b>
                                                    <ul>
                                                        <li>
                                                            <b
                                                                >If trade is
                                                                opened today:</b
                                                            >
                                                            (trade close price -
                                                            trade open price) *
                                                            lot * contract size
                                                            * exchange price
                                                        </li>
                                                        <li>
                                                            <b
                                                                >If trade is
                                                                opened before
                                                                today:</b
                                                            >
                                                            (trade close price -
                                                            bid price at
                                                            previous EOD) * lot
                                                            * contract size *
                                                            exchange price
                                                        </li>
                                                    </ul>
                                                </li>
                                                <li>
                                                    <b>For sell trades: </b>
                                                    <ul>
                                                        <li>
                                                            <b
                                                                >If trade is
                                                                opened today:</b
                                                            >
                                                            (trade open price -
                                                            trade close price) *
                                                            lot * contract size
                                                            * exchange price
                                                        </li>
                                                        <li>
                                                            <b
                                                                >If trade is
                                                                opened before
                                                                today:</b
                                                            >
                                                            (ask price at
                                                            previous EOD - trade
                                                            close price) * lot *
                                                            contract size *
                                                            exchange price
                                                        </li>
                                                    </ul>
                                                </li>
                                            </ul>
                                        </div>
                                        <div>
                                            <b>Profit MT (In USD): </b>
                                            <ul>
                                                <li>
                                                    <b>For buy trades: </b>
                                                    (trade close price - trade
                                                    open price) * lot * contract
                                                    size * exchange price
                                                </li>
                                                <li>
                                                    <b>For sell trades: </b>
                                                    (trade open price - trade
                                                    close price) * lot *
                                                    contract size * exchange
                                                    price
                                                </li>
                                            </ul>
                                        </div>
                                    </v-alert>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                            <v-card>
                                <v-data-table
                                    :items-per-page="15"
                                    :headers="closeTradesHeader"
                                    :header-props="{ sortIcon: null }"
                                    :items="loginCloseTradesData"
                                    :loading="loading"
                                    :mobile-breakpoint="0"
                                    :footer-props="{
                                        'items-per-page-text': '',
                                    }"
                                >
                                    <template v-slot:header.profit="{ header }">
                                        {{ header.text }}
                                        <v-tooltip top>
                                            <template
                                                v-slot:activator="{ on, attrs }"
                                            >
                                                <v-icon
                                                    dark
                                                    v-bind="attrs"
                                                    v-on="on"
                                                    small
                                                    color="grey"
                                                >
                                                    mdi-information
                                                </v-icon>
                                            </template>
                                            <span
                                                >MT Profit in Account
                                                Currency</span
                                            >
                                        </v-tooltip>
                                    </template>
                                    <template
                                        v-slot:header.ProfitTotal="{ header }"
                                    >
                                        {{ header.text }}
                                        <v-tooltip top>
                                            <template
                                                v-slot:activator="{ on, attrs }"
                                            >
                                                <v-icon
                                                    dark
                                                    v-bind="attrs"
                                                    v-on="on"
                                                    small
                                                    color="grey"
                                                >
                                                    mdi-information
                                                </v-icon>
                                            </template>
                                            <span>MT Profit In USD</span>
                                        </v-tooltip>
                                    </template>
                                    <template v-slot:item="{ item }">
                                        <tr>
                                            <td column="PositionID">
                                                {{ item["positionID"] }}
                                            </td>
                                            <td column="Order">
                                                {{ item["order"] }}
                                            </td>
                                            <td column="Cmd">
                                                {{
                                                    item["cmd"].toString() ===
                                                    "0"
                                                        ? "buy"
                                                        : item[
                                                              "cmd"
                                                          ].toString() === "1"
                                                        ? "sell"
                                                        : item["cmd"].toString()
                                                }}
                                            </td>
                                            <td column="Volume" align="right">
                                                {{
                                                    (
                                                        item["volume"] / 100
                                                    ).toFixed(2)
                                                }}
                                            </td>
                                            <td column="OpenTime">
                                                {{
                                                    toMTTimeString(
                                                        item["openTime"]
                                                    )
                                                }}
                                            </td>
                                            <td column="CloseTime">
                                                {{
                                                    toMTTimeString(
                                                        item["closeTime"]
                                                    )
                                                }}
                                            </td>
                                            <td
                                                column="OpenPrice"
                                                align="right"
                                            >
                                                {{
                                                    decimalPlaceCorrection(
                                                        symbol,
                                                        item["openPrice"]
                                                    )
                                                }}
                                            </td>
                                            <td
                                                column="ClosePrice"
                                                align="right"
                                            >
                                                {{
                                                    decimalPlaceCorrection(
                                                        symbol,
                                                        item["closePrice"]
                                                    )
                                                }}
                                            </td>
                                            <td column="EodAsk" align="right">
                                                {{
                                                    decimalPlaceCorrection(
                                                        symbol,
                                                        item["tradePriceInfo"][
                                                            "eodAsk"
                                                        ]
                                                    )
                                                }}
                                            </td>
                                            <td column="EodBid" align="right">
                                                {{
                                                    decimalPlaceCorrection(
                                                        symbol,
                                                        item["tradePriceInfo"][
                                                            "eodBid"
                                                        ]
                                                    )
                                                }}
                                            </td>
                                            <td column="Profit" align="right">
                                                {{ item["profit"].toFixed(2) }}
                                            </td>
                                            <td
                                                column="ProfitTotal"
                                                align="right"
                                            >
                                                {{
                                                    item["tradeProfitInfo"][
                                                        "profitTotal"
                                                    ].toFixed(2)
                                                }}
                                            </td>
                                            <td
                                                column="ProfitEOD"
                                                align="right"
                                            >
                                                {{
                                                    item["tradeProfitInfo"][
                                                        "profitEOD"
                                                    ].toFixed(2)
                                                }}
                                            </td>
                                            <td column="Tp" align="right">
                                                {{
                                                    decimalPlaceCorrection(
                                                        symbol,
                                                        item["tp"]
                                                    )
                                                }}
                                            </td>
                                            <td column="Sl" align="right">
                                                {{
                                                    decimalPlaceCorrection(
                                                        symbol,
                                                        item["sl"]
                                                    )
                                                }}
                                            </td>
                                            <td column="Reason">
                                                {{
                                                    reasonList[
                                                        item[
                                                            "reason"
                                                        ].toString()
                                                    ]
                                                }}
                                            </td>
                                            <td
                                                column="Commission"
                                                align="right"
                                            >
                                                {{
                                                    item["commission"].toFixed(
                                                        2
                                                    )
                                                }}
                                            </td>
                                            <td column="Storage" align="right">
                                                {{ item["storage"].toFixed(2) }}
                                            </td>

                                            <td column="extraInfo">
                                                <v-tooltip top>
                                                    <template
                                                        v-slot:activator="{
                                                            on,
                                                            attrs,
                                                        }"
                                                    >
                                                        <v-icon
                                                            dark
                                                            v-bind="attrs"
                                                            v-on="on"
                                                            class="pr-2"
                                                        >
                                                            mdi-swap-horizontal
                                                        </v-icon>
                                                    </template>
                                                    <div>
                                                        <table>
                                                            <tr>
                                                                <td
                                                                    class="pr-2"
                                                                >
                                                                    Exchange
                                                                    Symbol:
                                                                </td>
                                                                <td>
                                                                    {{
                                                                        item[
                                                                            "tradePriceInfo"
                                                                        ][
                                                                            "exchangeSymbol"
                                                                        ]
                                                                    }}
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>
                                                                    Exchange
                                                                    Ask:
                                                                </td>
                                                                <td>
                                                                    {{
                                                                        item[
                                                                            "tradePriceInfo"
                                                                        ][
                                                                            "exchangeAsk"
                                                                        ].toFixed(
                                                                            2
                                                                        )
                                                                    }}
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>
                                                                    Exchange
                                                                    Bid:
                                                                </td>
                                                                <td>
                                                                    {{
                                                                        item[
                                                                            "tradePriceInfo"
                                                                        ][
                                                                            "exchangeBid"
                                                                        ].toFixed(
                                                                            2
                                                                        )
                                                                    }}
                                                                </td>
                                                            </tr>
                                                        </table>
                                                    </div>
                                                </v-tooltip>

                                                <v-tooltip top>
                                                    <template
                                                        v-slot:activator="{
                                                            on,
                                                            attrs,
                                                        }"
                                                    >
                                                        <v-icon
                                                            dark
                                                            v-bind="attrs"
                                                            v-on="on"
                                                        >
                                                            mdi-calculator-variant
                                                        </v-icon>
                                                    </template>
                                                    <div>
                                                        <b
                                                            >Profit EOD (In
                                                            USD):</b
                                                        >
                                                        <div>
                                                            {{
                                                                calculationTooltipInWords(
                                                                    "close",
                                                                    "EOD",
                                                                    item
                                                                )
                                                            }}
                                                        </div>
                                                        <div>
                                                            {{
                                                                calculationTooltipInNumber(
                                                                    "close",
                                                                    "EOD",
                                                                    item
                                                                )
                                                            }}
                                                        </div>
                                                        <b
                                                            >Profit MT (In
                                                            USD):</b
                                                        >
                                                        <div>
                                                            {{
                                                                calculationTooltipInWords(
                                                                    "close",
                                                                    "MT",
                                                                    item
                                                                )
                                                            }}
                                                        </div>
                                                        <div>
                                                            {{
                                                                calculationTooltipInNumber(
                                                                    "close",
                                                                    "MT",
                                                                    item
                                                                )
                                                            }}
                                                        </div>
                                                    </div>
                                                </v-tooltip>
                                            </td>
                                        </tr>
                                    </template>
                                </v-data-table>
                            </v-card>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-expansion-panels>
            </v-col>
        </v-row>
    </div>
</template>
<script>
import { mapState, mapActions } from "vuex";
import dayjs from "dayjs";
import { helper } from "@components/mixins/helper";

export default {
    mixins: [helper],
    props: {
        loginValue: null,
        server: null,
        symbol: null,
    },
    data() {
        return {
            todayStart: parseInt(this.getMT4Int() / 86400) * 86400,
            tileDisplay: [
                {
                    text: "Symbol",
                    value: "Symbol",
                },
                {
                    text: "Contract Size",
                    value: "ContractSize",
                },
                {
                    text: "Profit Mode",
                    value: "ProfitMode",
                },
                {
                    text: "Type Name",
                    value: "TypeName",
                },
                {
                    text: "Server",
                    value: "server",
                },
            ],
            expansionList: [0],
        };
    },
    computed: {
        ...mapState("LoginSummary", [
            "closeTradesHeader",
            "openTradesHeader",
            "loginCloseTradesData",
            "loginOpenTradesData",
            "reasonList",
            "loading",
            "openTradeLoading",
            "profitMode",
        ]),
        ...mapState("Home", ["lotDigits"]),
        mobileExpansionContentClass() {
            return this.VUETIFY_BREAKPOINT.mobile ? "content-padding" : "";
        },
        cols() {
            return this.VUETIFY_BREAKPOINT.mobile ? 6 : 2;
        },
        mergedData() {
            return [...this.loginOpenTradesData, ...this.loginCloseTradesData];
        },
        profitModeOutput() {
            if (this.mergedData.length) {
                let output = "N/A";
                if (this.server.toString().toUpperCase().includes("MT4")) {
                    output =
                        this.profitMode.MT4[this.mergedData[0].profitMode] ||
                        "N/A";
                } else if (
                    this.server.toString().toUpperCase().includes("MT5")
                ) {
                    output =
                        this.profitMode.MT5[this.mergedData[0].profitMode] ||
                        "N/A";
                } else {
                }
                return output;
            } else {
                return "N/A";
            }
        },
    },
    methods: {
        ...mapActions("LoginSummary", [
            "getLoginCloseTradesAction",
            "getLoginOpenTradesAction",
        ]),
        refresh() {
            const tradesParams = {
                login: this.loginValue,
                symbol: this.symbol,
            };
            this.getLoginCloseTradesAction({
                ...tradesParams,
                liveOrHis: false,
            });
            this.getLoginOpenTradesAction({ ...tradesParams, liveOrHis: true });
        },
        decimalPlaceCorrection(symbol, symbolPrice) {
            const numberParts = symbolPrice.toString().split(".");
            if (numberParts.length == 1) {
                return numberParts[0];
            } else {
                return numberParts[0] + "." + numberParts[1].substring(0, 5);
            }
        },
        calculationTooltipInWords(mode, type, item) {
            if (mode === "open") {
                if (type === "MT") {
                    if (item.cmd === 0) {
                        return "(current bid price - trade open price) * lot * contract size * exchange price";
                    } else if (item.cmd === 1) {
                        return "(trade open price - current ask price) * lot * contract size * exchange price";
                    } else {
                        return "unknown type";
                    }
                } else {
                    if (item.cmd === 0) {
                        if (item.openTime > this.todayStart) {
                            return "(current bid price - trade open price) * lot * contract size * exchange price";
                        } else {
                            return "(current bid price - bid price at previous EOD) * lot * contract size * exchange price";
                        }
                    } else if (item.cmd === 1) {
                        if (item.openTime > this.todayStart) {
                            return "(trade open price - current ask price) * lot * contract size * exchange price";
                        } else {
                            return "(ask price at previous EOD - current ask price) * lot * contract size * exchange price";
                        }
                    } else {
                        return "unknown type";
                    }
                }
            } else if (mode === "close") {
                if (type === "MT") {
                    if (item.cmd === 0) {
                        return "(trade close price - trade open price) * lot * contract size * exchange price";
                    } else if (item.cmd === 1) {
                        return "(trade open price - trade close price) * lot * contract size * exchange price";
                    } else {
                        return "unknown type";
                    }
                } else {
                    if (item.cmd === 0) {
                        if (item.openTime > this.todayStart) {
                            return "(trade close price - trade open price) * lot * contract size * exchange price";
                        } else {
                            return "(trade close price - bid price at previous EOD) * lot * contract size * exchange price";
                        }
                    } else if (item.cmd === 1) {
                        if (item.openTime > this.todayStart) {
                            return "(trade open price - trade close price) * lot * contract size * exchange price";
                        } else {
                            return "(ask price at previous EOD - trade close price) * lot * contract size * exchange price";
                        }
                    } else {
                        return "unknown type";
                    }
                }
            } else {
                return null;
            }
        },
        calculationTooltipInNumber(mode, type, item) {
            if (mode === "open") {
                if (type === "MT") {
                    if (item.cmd === 0) {
                        return (
                            "(" +
                            item.tradePriceInfo.currentBid +
                            " - " +
                            item.openPrice +
                            ") * " +
                            (item.volume / 100).toFixed(2) +
                            " * " +
                            item.contractSize +
                            " * " +
                            item.tradePriceInfo.exchangeAsk +
                            " = " +
                            item.tradeProfitInfo.profitTotal.toFixed(2)
                        );
                    } else if (item.cmd === 1) {
                        return (
                            "(" +
                            item.openPrice +
                            " - " +
                            item.tradePriceInfo.currentAsk +
                            ") * " +
                            (item.volume / 100).toFixed(2) +
                            " * " +
                            item.contractSize +
                            " * " +
                            item.tradePriceInfo.exchangeAsk +
                            " = " +
                            item.tradeProfitInfo.profitTotal.toFixed(2)
                        );
                    } else {
                        return "unknown type";
                    }
                } else {
                    if (item.cmd === 0) {
                        if (item.openTime > this.todayStart) {
                            return (
                                "(" +
                                item.tradePriceInfo.currentBid +
                                " - " +
                                item.openPrice +
                                ") * " +
                                (item.volume / 100).toFixed(2) +
                                " * " +
                                item.contractSize +
                                " * " +
                                item.tradePriceInfo.exchangeAsk +
                                " = " +
                                item.tradeProfitInfo.profitEOD.toFixed(2)
                            );
                        } else {
                            return (
                                "(" +
                                item.tradePriceInfo.currentBid +
                                " - " +
                                item.tradePriceInfo.eodBid +
                                ") * " +
                                (item.volume / 100).toFixed(2) +
                                " * " +
                                item.contractSize +
                                " * " +
                                item.tradePriceInfo.exchangeAsk +
                                " = " +
                                item.tradeProfitInfo.profitEOD.toFixed(2)
                            );
                        }
                    } else if (item.cmd === 1) {
                        if (item.openTime > this.todayStart) {
                            return (
                                "(" +
                                item.openPrice +
                                " - " +
                                item.tradePriceInfo.currentAsk +
                                ") * " +
                                (item.volume / 100).toFixed(2) +
                                " * " +
                                item.contractSize +
                                " * " +
                                item.tradePriceInfo.exchangeAsk +
                                " = " +
                                item.tradeProfitInfo.profitEOD.toFixed(2)
                            );
                        } else {
                            return (
                                "(" +
                                item.tradePriceInfo.eodAsk +
                                " - " +
                                item.tradePriceInfo.currentAsk +
                                ") * " +
                                (item.volume / 100).toFixed(2) +
                                " * " +
                                item.contractSize +
                                " * " +
                                item.tradePriceInfo.exchangeAsk +
                                " = " +
                                item.tradeProfitInfo.profitEOD.toFixed(2)
                            );
                        }
                    } else {
                        return "unknown type";
                    }
                }
            } else if (mode === "close") {
                if (type === "MT") {
                    if (item.cmd === 0) {
                        return (
                            "(" +
                            item.closePrice +
                            " - " +
                            item.openPrice +
                            ") * " +
                            (item.volume / 100).toFixed(2) +
                            " * " +
                            item.contractSize +
                            " * " +
                            item.tradePriceInfo.exchangeAsk +
                            " = " +
                            item.tradeProfitInfo.profitTotal.toFixed(2)
                        );
                    } else if (item.cmd === 1) {
                        return (
                            "(" +
                            item.openPrice +
                            " - " +
                            item.closePrice +
                            ") * " +
                            (item.volume / 100).toFixed(2) +
                            " * " +
                            item.contractSize +
                            " * " +
                            item.tradePriceInfo.exchangeAsk +
                            " = " +
                            item.tradeProfitInfo.profitTotal.toFixed(2)
                        );
                    } else {
                        return "unknown type";
                    }
                } else {
                    if (item.cmd === 0) {
                        if (item.openTime > this.todayStart) {
                            return (
                                "(" +
                                item.closePrice +
                                " - " +
                                item.openPrice +
                                ") * " +
                                (item.volume / 100).toFixed(2) +
                                " * " +
                                item.contractSize +
                                " * " +
                                item.tradePriceInfo.exchangeAsk +
                                " = " +
                                item.tradeProfitInfo.profitEOD.toFixed(2)
                            );
                        } else {
                            return (
                                "(" +
                                item.closePrice +
                                " - " +
                                item.tradePriceInfo.eodBid +
                                ") * " +
                                (item.volume / 100).toFixed(2) +
                                " * " +
                                item.contractSize +
                                " * " +
                                item.tradePriceInfo.exchangeAsk +
                                " = " +
                                item.tradeProfitInfo.profitEOD.toFixed(2)
                            );
                        }
                    } else if (item.cmd === 1) {
                        if (item.openTime > this.todayStart) {
                            return (
                                "(" +
                                item.openPrice +
                                " - " +
                                item.closePrice +
                                ") * " +
                                (item.volume / 100).toFixed(2) +
                                " * " +
                                item.contractSize +
                                " * " +
                                item.tradePriceInfo.exchangeAsk +
                                " = " +
                                item.tradeProfitInfo.profitEOD.toFixed(2)
                            );
                        } else {
                            return (
                                "(" +
                                item.tradePriceInfo.eodAsk +
                                " - " +
                                item.closePrice +
                                ") * " +
                                (item.volume / 100).toFixed(2) +
                                " * " +
                                item.contractSize +
                                " * " +
                                item.tradePriceInfo.exchangeAsk +
                                " = " +
                                item.tradeProfitInfo.profitEOD.toFixed(2)
                            );
                        }
                    } else {
                        return "unknown type";
                    }
                }
            } else {
                return null;
            }
        },
        toMTTimeString(timestamp) {
            const date = dayjs
                .unix(timestamp)
                .tz("UTC")
                .format("YYYY-MM-DD HH:mm:ss");
            return date;
        },
    },
};
</script>
<style lang="scss" scoped>
b {
    font-weight: 800;
}
.v-tooltip__content.menuable__content__active {
    opacity: 1 !important;
    background-color: gray;
}
.content-padding {
    ::v-deep {
        .v-expansion-panel-content__wrap {
            padding: 0 8px 16px;
        }
    }
}
</style>
